import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, InputLabel, Grid2, Checkbox, InputAdornment, IconButton, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import mixpanel from 'mixpanel-browser';

interface OddsFormProps {
    onSubmit: (odds: number[]) => void;
    selections: Selection[];
    setSelections: (selections: Selection[]) => void;
}

export interface Selection {
    odds: number;
    winner: boolean;
};

const OddsForm: React.FC<OddsFormProps> = ({ selections, setSelections }) => {
    const [stake, setStake] = useState<number>(1);
    const [radio, setRadio] = useState<string>('perCombo');

    const addSelection = () => {
        setSelections([...selections, { odds: 2, winner: true }]);
        mixpanel.track("added_selection", {
            count: selections.length
        });
    };

    const deleteSelection = (index: number) => {
        const newSelections = [...selections];
        newSelections.splice(index, 1);
        setSelections(newSelections);
        mixpanel.track("deleted_selection", {
            index: index
        });
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setRadio(value);
        mixpanel.track("stake_type_changed", {
            value: value
        });
    };

    const handleStakeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        setStake(value);
        mixpanel.track("stake_changed", {
            value: value
        });
    };

    return (
        <>
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, sm: 4 }}>
                    <h2>Selections</h2>
                    {selections.map((selection, index) => (
                        <OddEntry
                            key={index}
                            index={index}
                            odd={selection.odds}
                            onDelete={() => deleteSelection(index)}
                            oddsChanged={(index, value, winner) => {
                                const newSelections = [...selections];
                                newSelections[index] = { odds: value, winner };
                                setSelections(newSelections);
                                mixpanel.track("selection_value_changed", {
                                    index: index,
                                    value: value
                                });
                            }} />
                    ))}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={addSelection}
                        sx={{ mt: 2 }}
                    >
                        + Add Selection
                    </Button>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 4 }}>
                    <h2>Stake</h2>
                    <RadioGroup
                        value={radio}
                        onChange={handleRadioChange}
                        aria-label="stake radio"
                        sx={{ mt: 2, flexDirection: 'row' }}
                    >
                        <FormControlLabel value="perCombo" control={<Radio />} label="Per combo" />
                        <FormControlLabel value="total" control={<Radio />} label="Total" />
                    </RadioGroup>
                    <TextField
                        variant="outlined"
                        type="number"
                        value={stake}
                        onChange={handleStakeChange}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },
                        }}
                    />

                </Grid2>
                <Grid2 size={{ xs: 12, sm: 4 }}>
                    <h2>Outcomes</h2>

                    {selections.length > 0 && selections.map((selection, index) => (
                        <>
                            <Box key={index}>
                                <h3>{betNameType(index + 1, selections.length)}</h3>
                                <p>
                                    # of bets: {combinations(selections.length, index + 1)}
                                    <br />
                                    Cost: ${(combinations(selections.length, index + 1) * (radio === 'perCombo' ? stake : stake / combinations(selections.length, index + 1))).toFixed(2)}
                                    <br />
                                    Payout: ${getPriceByBetType(selections.filter(x => x.winner).flatMap(x => x.odds), index + 1, (radio === 'perCombo' ? stake : stake / combinations(selections.length, index + 1))).toFixed(2)}
                                </p>
                            </Box>
                        </>
                    ))}
                </Grid2>
            </Grid2>
        </>
    );
};


interface OddEntryProps {
    index: number;
    odd: number;
    oddsChanged: (index: number, value: number, winner: boolean) => void;
    onDelete: (index: number) => void;
}

const OddEntry: React.FC<OddEntryProps> = ({ index, odd, oddsChanged, onDelete }) => {
    const [odds, setOdds] = useState<string>(odd.toString());
    const [winner, setWinner] = useState<boolean>(true);

    useEffect(() => {
        setOdds(odd.toString());
    }, [odd]);

    const handleOddsChange = (value: string) => {
        setOdds(value);
        oddsChanged(index, parseFloat(value), winner);
        mixpanel.track("odds_changed", {
            index: index,
            value: value
        });
    };

    return (
        <Box>
            <InputLabel>Selection {index + 1} odds</InputLabel>
            <Grid2 container spacing={2}>
                <Grid2>
                    <TextField
                        variant="outlined"
                        value={odds}
                        onChange={(e) => handleOddsChange(e.target.value)}
                        type="number"
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },
                        }}
                    />
                </Grid2>
                <Grid2>
                    <Checkbox
                        title="Winner"
                        checked={winner}
                        onChange={(e) => { setWinner(e.target.checked); oddsChanged(index, parseFloat(odds), e.target.checked); }}
                    ></Checkbox>
                    <IconButton
                        title="Delete"
                        onClick={() => onDelete(index)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid2>
            </Grid2>
        </Box>
    );
}

// Function to generate combinations of a certain size
function getCombinations<T>(array: T[], size: number): T[][] {
    const combinations: T[][] = [];
    function combine(start: number, chosen: T[]) {
        if (chosen.length === size) {
            combinations.push([...chosen]);
            return;
        }
        for (let i = start; i < array.length; i++) {
            chosen.push(array[i]);
            combine(i + 1, chosen);
            chosen.pop();
        }
    }
    combine(0, []);
    return combinations;
}

// Function to calculate the all-up price for a single combination of odds
function allUpPrice(odds: number[]): number {
    return odds.reduce((accumulator, currentOdd) => accumulator * currentOdd, 1);
}

// Function to calculate factorial
function factorial(num: number): number {
    if (num <= 1) return 1;
    return num * factorial(num - 1);
}

// Function to calculate combinations (n choose k)
function combinations(n: number, k: number): number {
    return factorial(n) / (factorial(k) * factorial(n - k));
}

function getPriceByBetType(odds: number[], betType: number, stake: number): number {
    const x = getCombinations(odds, betType); // Generate combinations of 3 (trebles)
    let totalPayout = 0;

    for (const treble of x) {
        const payout = allUpPrice(treble) * stake;
        totalPayout += payout;
    }

    return totalPayout;
}

function betNameType(fold: number, total: number): string {
    if (total > 1 && fold === total) return "All up";
    if (fold === 1) return "Singles";
    if (fold === 2) return "Doubles";
    if (fold === 3) return "Trebles";
    return `${fold}-folds`;
}

export default OddsForm;
