import React, { useState } from 'react';
import { Container, Typography, IconButton } from '@mui/material';
import { Brightness4, Brightness7, Refresh } from '@mui/icons-material';
import OddsForm from '../components/OddsForm';
import Results from '../components/Results';
import { useTheme } from '../App';
import { Selection } from '../components/OddsForm';

const HomePage: React.FC = () => {
  const [odds, setOdds] = useState<number[]>([]);
  const { toggleTheme } = useTheme();
  const [darkMode, setDarkMode] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [selections, setSelections] = useState<Selection[]>([
    { odds: 2, winner: true },
    { odds: 2, winner: true },
    { odds: 2, winner: true }
  ]);

  const handleToggleTheme = () => {
    toggleTheme();
    setDarkMode(!darkMode);
  };

  const handleOddsSubmit = (odds: number[]) => {
    setOdds(odds);
  };

  const handleResetForm = () => {
    setResetForm(!resetForm);
    setSelections([
      { odds: 2, winner: true },
      { odds: 2, winner: true },
      { odds: 2, winner: true }
    ]);
  }

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
        <Typography variant="h4">Multi-Bet Odds Calculator</Typography>
        <div>
          <IconButton onClick={handleResetForm}>
            <Refresh />
          </IconButton>
          <IconButton onClick={handleToggleTheme}>
            {darkMode ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
        </div>
      </div>
      <OddsForm
        selections={selections}
        setSelections={setSelections}
        onSubmit={handleOddsSubmit} />
      {odds.length > 0 && <Results odds={odds} />}
    </Container>
  );
};

export default HomePage;
