import React from 'react';
import { Box, Typography } from '@mui/material';

interface ResultsProps {
  odds: number[];
}

const Results: React.FC<ResultsProps> = ({ odds }) => {
  const calculateOutcomes = (odds: number[]) => {
    // Example calculation logic
    return odds.reduce((acc, odd) => acc * odd, 1);
  };

  const outcome = calculateOutcomes(odds);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Possible Outcome</Typography>
      <Typography variant="body1">{outcome}</Typography>
    </Box>
  );
};

export default Results;